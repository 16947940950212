<template>
  <div class="jcsystem">
    <Jcheader />
    <jclist></jclist>
  </div>
</template>

<script>
import Jcheader from "@/components/jcmodule/jcheader";
import jclist from "@/views/jclist";
export default {
  data() {
    return {};
  },
  components: {
    Jcheader,
    jclist
  },
  created() {},

  methods: {}
};
</script>

<style scoped>
.jcsystem {
  height: 100%;
}
</style>
