<template>
  <div>
    <div style="margin-top: 0.2rem; margin-left: 0.3rem" v-if="!ison">
      请选择使用的检测系统：
    </div>
    <div>
      <div style="margin-bottom: 0.3rem" v-for="item in list" :key="item.id" @click="toJcfenlei(item.id)">
        <div style="
                          background: rgb(255, 255, 255);
                          padding: 0.3rem 0.2rem;
                          position: relative;
                        ">
          <div style="display: flex; align-items: center">
            <div style="width: 33%">
              <img :src="item.url" alt="" style="width: 2rem; height: 2rem; border-radius: 50%" />
            </div>
            <div style="width: 100%; margin-left: 0.28rem">
              <div style="color: rgb(123, 122, 122); font-size: 0.28rem">
                超高性价比 自检首选<text style="color: rgb(253, 88, 26); margin-left: 0.3rem">首次免费检测一万字</text>
              </div>
              <div style="font-size: 0.38rem; font-weight: 900; margin: 0.2rem 0px">
                {{ item.sname }}
              </div>
              <div style="color: rgb(179, 175, 175); font-size: 0.28rem">
                适合初稿自检,检测结果接近维普
              </div>
              <div style="
                                display: flex;
                                color: rgb(179, 175, 175);
                                font-size: 0.3rem;
                                margin-top: 0.2rem;
                              ">
                推荐度：
                <div v-for="star in 5">
                  <img style="width: 0.3rem; height: 0.3rem" src="../assets/img/home/star.png" />
                </div>
              </div>
            </div>
          </div>
          <img :src="item.icon" style="position: absolute; right: 0px; bottom: 0px; height: 1.3rem" />
        </div>
        <img src="../assets/img/home/fengexian.png" style="
                          width: 100%;
                          height: 0.3rem;
                          margin: 0.37rem 0px;
                          display: block;
                        " v-if="item.id == 1" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          id: 1,
          url: require("../assets/img/home/checkLike.png"),
          sname: "联文检测旗舰版",
          text: "(支持中、英文论文)",
          icon: require("../assets/img/home/shouxuan.png"),
        },
        {
          id: 2,
          url: require("../assets/img/home/weipu.png"),
          sname: "维普论文检测系统",
          text: "(支持中、英、日文)",
          icon: require("../assets/img/home/jiuzhe.png"),
        },
        {
          id: 4,
          url: require("../assets/img/home/wanfang.png"),
          sname: "万方数据™论文检测",
          text: "(仅支持中文)",
        },
        {
          id: 3,
          url: require("../assets/img/home/zhiwang.jpg"),
          sname: "知网学术不端行为检测系统",
          text: "(支持中、英、日文)",
        },
      ],
    };
  },
  props: ["ison"],
  mounted() {
    console.log(this.ison, "fdsf")
  },
  methods: {
    toJcfenlei(id) {
      console.log(id);
      this.$router.push({
        path: "/jcfenlei",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style scoped>
.title {
  display: flex;
  background: #228ef7;
}

.lefticon {
  line-height: 0.8rem;
  padding: 0 0.3rem;
  color: #fff;
  font-size: 0.4rem;
}

.title p {
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  color: #fff;
  flex: 0.85;
}

.container {
  padding: 0.3rem 0.59rem;
}

.container ul {
  width: 100%;
  margin-bottom: 0.39rem;
  border-bottom: 1px solid #ccc;
}

.container ul p {
  margin: 0.2rem 0;
  text-align: center;
  font-size: 0.32rem;
  font-weight: bold;
}

.container ul p span {
  font-size: 0.28rem;

  font-weight: normal;
}

.container ul img {
  width: 100%;
  height: 3rem;
  border: 1px solid #ddd;
}
</style>